<template>
    <div>
        <v-card-title class="title pa-3 warning white--text">
        <v-icon class="mr-2 white--text">warning</v-icon>
        Rollback Reason!
        </v-card-title>
        <br />
        <v-card-title class="title pa-3">
        <v-textarea
            outlined
            name="input-7-4"
            label="Reason to Rollback"
            v-model="localRollbackReason"
        ></v-textarea>

        <br />
        <alert-message
            :extraSmall="true"
            type="warning"
            :title="'Rollback!! (' + $auth.user().full_name + ')'"
            message="Make sure what you are doing. Frequent rollback actions might cause issue on the billing process"
        />
        </v-card-title>
    </div>
</template>

<script>

    export default {
        props: {
            rollbackReason: {
                default: '',
            },
        },
        watch: {
            localRollbackReason(newValue) {
                this.$emit('update:rollbackReason', newValue);
            },
        },
        data: () => ({
            localRollbackReason: ''
        })

    }
</script>